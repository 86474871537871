import { useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import ParticipantEditForm from 'manage-tritag/components/pages/participants/edit-form'
import TabPanel from 'manage-tritag/components/tab-panel'
import { Box, Tab, Tabs } from '@mui/material'
import { useGetRegistrationHistoryQuery } from 'manage-tritag/services/api/endpoints/participants'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

const ParticipantPage = (props: PageProps) => {
  const { params } = props
  const { data, isLoading } = useGetRegistrationHistoryQuery(params?.id)
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const headCells: HeadCell[] = [
    {
      id: 'season',
      numeric: false,
      disablePadding: false,
      label: 'Season',
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'team',
      numeric: false,
      disablePadding: false,
      label: 'Team',
    },
    {
      id: 'association',
      numeric: false,
      disablePadding: false,
      label: 'Association',
    },
  ]
  const bodyCells = ['season', 'role', 'team', 'association']

  return (
    <Layout label1="Participants" label2="Update" url="/participants">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '1rem' }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Member details" sx={{ fontWeight: 'bold' }} />
            <Tab label="Registration history" sx={{ fontWeight: 'bold' }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ParticipantEditForm {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <>
            {!isLoading && data && (
              <EnhancedTable
                tableTitle="Registration History"
                uniqueIdentifier="_id"
                participant
                rows={data.map((registration: any) => ({
                  season: registration.season.name,
                  role: registration.role.toUpperCase(),
                  association: registration.association.name,
                  team: registration.teamId ? registration.teamId.name : 'None',
                }))}
                headCells={headCells}
                bodyCells={bodyCells}
                handleRowClick={() => {}}
              />
            )}
          </>
        </TabPanel>
      </Box>
    </Layout>
  )
}

export default withAuthenticationRequired(ParticipantPage)
